import * as React from "react"
import { graphql, Link } from "gatsby"
import FrontmatterPageContext from "~context/FakePageContext"
// import { LinkDataURL } from "~components/data_url"
import { Box, Text, Stack } from "@fastly/consistently"
import { main } from "~vanilla/layout-default.css"
import { mainArea } from "~vanilla/layout-default-full.css"

import LayoutCore from "~templates/layout-core"

import NavGuideData from "~data/nav_ngwaf.yml"

import { FavHead } from "~components/Head/FavHead"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { CodeBlock } from "~components/CodeBlock"
import Alert from "~components/alert"
import T from "~components/translator"
import mailto from "~components/mailto"
import DRY from "~components/dry"
import Raw from "~components/raw"
import Frontmatter from "~components/frontmatter"
import { LimitedAvailBadge } from "~components/avail-badges"
import FloatedImage from "~components/floated-image"
import { PageHeaderTable, DinkusBar } from "~components/AIOHelper/AIOHelper"

import * as Includes from "~components/includes"
// these came from the /index.md page
let frontmatter = {
  // layout: "default_full",
  section: "ngwaf",
  section_safe: "ngwaf",
  title: "Fastly Next-Gen WAF Archive | Fastly Documentation",
  lang: "en",
}

// for MDXProvider later
let mdx_components = {
  T,
  Alert,
  mailto,
  ...Includes,
  DRY,
  Raw,
  Box,
  CodeBlock,
  pre: CodeBlock,
  FloatedImage,
  Frontmatter,
  LimitedAvailBadge,
}

const NgwafEnAioPage = ({ data, location }) => {
  let GPrimaryDatas = {}
  let GSecondaryData = data.gsecondaries.nodes
  NavGuideData.forEach((element) => {
    const { crumb: pkey, "secondary-nav": snav = [] } = element

    let thisPrimary = data.gprimaries.nodes.filter((nod) => nod.frontmatter.cat_id === pkey)[0]
    let p_frontmatter = thisPrimary.frontmatter

    // its ugly, but we can hardcode this since tihs is only used on this en/ngwaf page
    p_frontmatter["section_url"] = "/en/ngwaf/"

    let thisPrimaryGuides = data.guides_in_primarys.group.find((ggip) => ggip.fieldValue === pkey)

    let myOrphans = data.gprimary_orphans.group.find((g) => g.fieldValue === pkey)

    GPrimaryDatas[pkey] = {
      pkey: pkey,
      pfrontmatter: p_frontmatter,
      thisPrimaryGuidesNodes: thisPrimaryGuides?.nodes,
      myOrphans: myOrphans?.nodes,
    }
  })

  let FPrimaryDatas = {}
  let FSecondaryData = data.gsecondaries.nodes

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main layout-guide_article`}>
            <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
              <div className="content-content">

                <h1>Next-Gen WAF guides</h1>
                {NavGuideData.map((ngd) => {
                  let pdata = GPrimaryDatas[ngd.crumb]
                  let { "secondary-nav": secnavs } = ngd
                  if (ngd?.unlisted) {
                    return <></>
                  }
                  let pURL = `${pdata.pfrontmatter.section_url}${pdata.pkey}`

                  return (
                    <section>
                      <Box bg={`var(--color-blue-20)`} border="5px outset #999999" padding="1em" mb="0.5em">
                        <Text variant="display2xl">
                          Category: <a href={pURL}>{pdata.pfrontmatter.section_name}</a>
                        </Text>
                        <p style={{ marginBottom: 0 }}>{pdata.pfrontmatter.description}</p>
                      </Box>

                      {secnavs?.map((secondary) => {
                        const { crumb: scrumb } = secondary
                        const sdata = GSecondaryData?.find(
                          (secnode) => secnode.frontmatter.crumb === scrumb
                        ).frontmatter
                        // let sdata = undefined
                        const sguides = pdata?.thisPrimaryGuidesNodes?.filter(
                          (gip) => gip.frontmatter.secondary_nav == scrumb
                        )
                        if (secondary?.unlisted) {
                          return <></>
                        }

                        return (
                          <>
                            <Box bg={`var(--color-blue-20)`} border="1px solid black" padding="0.5em" mb="0.5em">
                              <Text variant="displayxl">Subcategory: {sdata.name}</Text>
                              <p>{sdata.description}</p>
                            </Box>

                            {sguides?.map((sg) => {
                              return (
                                <>
                                  <PageHeaderTable guide={sg} section_url_prefix={pdata.pfrontmatter.section_url} />

                                  <hr />
                                  <FrontmatterPageContext.Provider value={sg.frontmatter}>
                                    <MDXProvider components={mdx_components}>
                                      <MDXRenderer>{sg.body}</MDXRenderer>
                                    </MDXProvider>
                                  </FrontmatterPageContext.Provider>

                                  <dinkusBar />
                                </>
                              )
                            })}
                          </>
                        )
                      })}

                      {pdata.myOrphans?.map((og) => {
                        return (
                          <>
                            <PageHeaderTable guide={og} section_url_prefix={pdata.pfrontmatter.section_url} />

                            <hr />
                            <FrontmatterPageContext.Provider value={og.frontmatter}>
                              <MDXProvider components={mdx_components}>
                                <MDXRenderer>{og.body}</MDXRenderer>
                              </MDXProvider>
                            </FrontmatterPageContext.Provider>

                            <DinkusBar />
                          </>
                        )
                      })}
                    </section>
                  )
                })}

              </div>
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default NgwafEnAioPage

export const Head = (props) => {
  return (
    <>
      <title>{frontmatter.title}</title>
      <meta name="robots" content="noindex"></meta>
      <FavHead />
    </>
  )
}

export const sitemapQuery = graphql`
  query {
    guides_in_primarys: allMdx(
      filter: { slug: { regex: "/^_en_ngwaf//" }, frontmatter: { unlisted: { ne: true }, orphan: { ne: true } } }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        totalCount
        nodes {
          slug
          frontmatter {
            primary_nav
            secondary_nav
            header
            unlisted
            last_updated(formatString: "YYYY-MM-DD")
          }
          body
        }
      }
    }
    gprimaries: allMdx(filter: { fileAbsolutePath: { regex: "/_en_ngwaf_primary/" } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          cat_id
          section_name
          svg
          description
        }
      }
    }
    gsecondaries: allMdx(filter: { fileAbsolutePath: { regex: "/_en_ngwaf_secondary/" } }) {
      nodes {
        fileAbsolutePath
        slug
        # body
        frontmatter {
          name
          crumb
          description
        }
      }
    }
    gprimary_orphans: allMdx(
      filter: {
        frontmatter: { orphan: { eq: true }, unlisted: { ne: true } }
        fileAbsolutePath: { regex: "/_en_ngwaf//" }
      }
      sort: { fields: fileAbsolutePath }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        nodes {
          fileAbsolutePath
          slug
          frontmatter {
            primary_nav
            # secondary_nav
            header
            unlisted
            # orphan
          }
          body
        }
      }
    }
  }
`
